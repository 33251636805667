<template>
  <div>
    <a-divider orientation="center">
      <h3>Progress of Subject Assignments</h3>
    </a-divider>
    <div class="card border">
      <chart-all-subject
        v-if="chartData.labels.length && chartData.series.length"
        :chartData="chartData"
        :isStudentView="false"
      />
      <EmptyState
        v-else
        heading="No Data"
        description="There is no chart on this student."
        class="my-5"
      />
    </div>
  </div>
</template>

<script>
const ChartAllSubject = () => import('@/components/app/ChartAllSubject')
const EmptyState = () => import('@/components/app/EmptyState')

export default {
  components: { ChartAllSubject, EmptyState },
  props: {
    idMurid: {
      type: Number,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        series: [],
        fullWidth: true,
      },
    }
  },
  methods: {
    async fetchStudentProgress() {
      try {
        const data = 53
        const res = await this.$store.dispatch('subject/FETCH_SUMMARY_ASSIGNMENT_ALL_SUBJECT', { idMurid: data })
        if (res.length) {
          const lengths = res.map(a => a.dataChart.length)
          const totalAssignment = res[lengths.indexOf(Math.max(...lengths))].totalAssignment
          // console.log(totalAssignment, res, 'masuk1')
          for (let i = 1; i <= totalAssignment; i++) {
            this.chartData.labels.push(`#${i}`)
          }
          // console.log(totalAssignment, res, 'masuk2')
          this.chartData.series = res.map((el, index) => {
            const series = []
            if (el.dataChart.length) {
              // console.log(el.dataChart)
              el.dataChart.forEach(elNilai => {
                series.push(elNilai.tugas_nilai)
              })
            }
            return {
              className: `ct-series-${this.abjad[index]}`,
              data: series,
              name: el.codeMapel,
            }
          })
        }
        // console.log(this.chartData, 'masuk3')
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    this.fetchStudentProgress()
  },
  computed: {
    abjad() {
      const a = []
      const j = 'z'.charCodeAt(0)
      for (let i = 'a'.charCodeAt(0); i <= j; i++) {
        a.push(String.fromCharCode(i))
      }
      return a
    },
  },
}
</script>

<style>
</style>
